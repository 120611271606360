import * as React from "react"
import {
  Button,
  FormFieldBlock,
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  Text,
  ThemeCss,
} from "gatsby-interface"
import { MdRefresh } from "react-icons/md"
import { useRepositoriesForInstallation } from "@modules/site/create/Import/hooks/useRepositoriesForInstallation"
import { SourceControlProvider, Repository } from "@modules/graphql/types"
import Loading from "@modules/ui/components/Loading"
import { importSite as text } from "@modules/locales/default.js"
import { debounce } from "@modules/ui/utils/debounce"

export type RepositoryFieldProps = {
  label: string
  sourceOrgId: string
  sourceProvider: SourceControlProvider
  selectedRepositoryId: string
  selectedRepositoryName: string
  isUserTeam: boolean
  handleRepositoryChange: (value: Repository | null) => void
  setError: (e: { message: string }) => void
}

export function RepositoryField({
  label,
  sourceOrgId,
  sourceProvider,
  selectedRepositoryId,
  selectedRepositoryName,
  handleRepositoryChange,
  isUserTeam,
  setError,
}: RepositoryFieldProps) {
  const [inputValue, setInputValue] = React.useState(``)
  const [inputError, setInputError] = React.useState(``)
  const installationId = React.useRef(sourceOrgId)

  React.useEffect(() => {
    if (sourceOrgId !== installationId.current) {
      setInputValue(``)
      installationId.current = sourceOrgId
    }
  }, [sourceOrgId])

  const [
    repositories,
    { loading, error, refetch },
  ] = useRepositoriesForInstallation(
    {
      installationId: sourceOrgId,
      provider: sourceProvider,
      isUserTeam,
    },
    () => undefined
  )

  if (error) {
    setError(error)
  }

  const debouncedHandleRepositoryChange = debounce(handleRepositoryChange, 500)

  const handleOnSelect = (value: string) => {
    setInputValue(value)

    const repository = repositories.find(repo => repo.name === value)

    if (repository) {
      handleRepositoryChange(repository as Repository)
      setInputError(``)
    }
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    setInputValue(newValue)

    const repository = repositories.find(repo => repo.name === newValue)

    if (!repository) {
      const errorMsg = newValue
        ? text.messages.noRepositoryWithThatName
        : text.messages.fieldIsRequiredSelectRepository

      setInputError(errorMsg)
      handleRepositoryChange(null)

      return
    }

    debouncedHandleRepositoryChange(repository as Repository)
    setInputError(``)
  }

  const handleOnRefreshButtonClick = () => {
    refetch()
  }

  const filterRepositories = (item: Pick<Repository, "name">) => {
    if (inputValue) {
      return item.name?.toLowerCase().includes(inputValue.toLowerCase())
    }

    return Boolean(item)
  }

  const filteredRepositories = repositories.filter(filterRepositories)

  return (
    <React.Fragment>
      <FormFieldBlock
        id="repoSelection"
        label={label}
        error={inputError}
        required
      >
        {controlProps => (
          <Combobox onSelect={handleOnSelect}>
            <ComboboxInput
              value={inputValue}
              onChange={handleOnChange}
              disabled={sourceOrgId === ``}
              data-cy="repo-list-search"
              autoComplete="off"
              showToggleButton
              selectedOptionLabel={selectedRepositoryName}
              {...controlProps}
            />
            <ComboboxPopover
              portal
              css={theme => [!loading && popoverCss(theme)]}
            >
              {!loading && (
                <Button
                  onClick={handleOnRefreshButtonClick}
                  size="M"
                  variant="GHOST"
                  rightIcon={<MdRefresh />}
                  css={refetchButtonCss}
                >
                  {text.actions.refreshList}
                </Button>
              )}

              <ComboboxList
                data-cy="repo-list"
                aria-label="Select a repository"
              >
                {loading ? (
                  <Loading
                    delay={1000}
                    message="Loading your repositories..."
                    variant="baby"
                    css={loadingCss}
                  />
                ) : (
                  <React.Fragment>
                    {repositories.length > 0 &&
                      filteredRepositories.map(repo => {
                        return (
                          <ComboboxOption
                            key={repo.id}
                            value={repo.name}
                            selected={selectedRepositoryId === repo.id}
                            highlightMatches={true}
                            data-cy={`repo-list-item-` + repo.name}
                          />
                        )
                      })}
                  </React.Fragment>
                )}
              </ComboboxList>

              {filteredRepositories.length === 0 && (
                <Text css={emptyListCss}>{text.messages.noRepos}</Text>
              )}
            </ComboboxPopover>
          </Combobox>
        )}
      </FormFieldBlock>
    </React.Fragment>
  )
}

/* styles */

const popoverCss: ThemeCss = theme => ({
  paddingBottom: theme.space[9],
})

const emptyListCss: ThemeCss = theme => ({
  margin: `${theme.space[3]} ${theme.space[5]}`,
  fontSize: `inherit`,
})

const loadingCss: ThemeCss = theme => ({
  margin: `${theme.space[8]} 0`,
  display: `flex`,
  justifyContent: `center`,
})

const refetchButtonCss: ThemeCss = theme => ({
  paddingTop: theme.space[3],
  paddingBottom: theme.space[3],
  borderTop: `1px solid ${theme.colors.grey[20]}`,
  borderRadius: 0,
  width: `100%`,
  position: `absolute`,
  bottom: 0,
  left: 0,
  right: 0,
})
